import React from "react"
import { Link, graphql } from "gatsby"
import ComparisonGraph from "../components/ComparisonGraph"
import DayComparisonGraph from "../components/DayComparisonGraph"

import Layout from "../components/layout"
import Situation from "../components/situation"

import styles from "./index.module.css"

const IndexPage = ({
  data: {
    countries: { edges },
  },
}) => {
  const [searchValue, setSearchValue] = React.useState("")
  const [selected, setSelected] = React.useState({})
  const [align, setAlign] = React.useState("none")
  const [cached, setCached] = React.useState({})
  const [currentMode, setCurrentMode] = React.useState("confirmed")
  const [isLogScale, setLogScale] = React.useState(false)

  const downloadIfNeeded = slug => {
    if (cached[slug] == "loading" && cached[slug] !== undefined) return
    setCached({ ...cached, [slug]: "loading" })
    fetch(`/${slug}.json`)
      .then(response => response.json())
      .then(data => {
        setCached({ ...cached, [slug]: data })
      })
      .catch(error => setCached({ ...cached, [slug]: error }))
  }

  const worldwide = edges.slice(1).reduce(
    (total, { node }) => {
      return {
        confirmed: parseInt(total.confirmed) + parseInt(node.confirmed),
        deaths: parseInt(total.deaths) + parseInt(node.deaths),
        recovered: parseInt(total.recovered) + parseInt(node.recovered ?? 0),
        deathsIncrease:
          parseInt(total.deathsIncrease) + parseInt(node.deathsIncrease ?? 0),
        confirmedIncrease:
          parseInt(total.confirmedIncrease) +
          parseInt(node.confirmedIncrease ?? 0),
        recoveredIncrease:
          parseInt(total.recoveredIncrease) +
          parseInt(node.recoveredIncrease ?? 0),
      }
    },
    {
      confirmed: 0,
      deaths: 0,
      recovered: 0,
      deathsIncrease: 0,
      recoveredIncrease: 0,
      confirmedIncrease: 0,
    }
  )

  let values = edges.slice(1)
  if (searchValue != "") {
    values = values.filter(
      ({ node }) =>
        node.country.toLowerCase().includes(searchValue) ||
        node.state.toLowerCase().includes(searchValue)
    )
  }

  return (
    <Layout>
      <h2>Worldwide situation</h2>
      <Situation
        confirmed={worldwide.confirmed}
        deaths={worldwide.deaths}
        recovered={worldwide.recovered}
        deathsIncrease={worldwide.deathsIncrease}
        confirmedIncrease={worldwide.confirmedIncrease}
        recoveredIncrease={worldwide.recoveredIncrease}
      />
      {Object.keys(selected).length > 0 &&
        (align === "none" ? (
          <ComparisonGraph
            data={cached}
            currentMode={currentMode}
            selected={selected}
            isLogScale={isLogScale}
          />
        ) : (
          <DayComparisonGraph
            data={cached}
            currentMode={currentMode}
            selected={selected}
            isLogScale={isLogScale}
            threshold={align}
          />
        ))}
      {Object.keys(selected).length === 0 && (
        <div className={styles.tutorial}>
          You can use the checkbox on the left of each line to compare data
        </div>
      )}
      <div className={styles.options}>
        <input
          type="text"
          placeholder="Country filter"
          value={searchValue}
          onChange={e => {
            setSearchValue(e.target.value.toLowerCase())
          }}
          className={styles.search}
        />

        <div>
          Comparison for:{" "}
          <select
            onChange={e => {
              setCurrentMode(e.target.value)
            }}
            value={currentMode}
          >
            <option value="confirmed">Confirmed</option>
            <option value="deaths">Deaths</option>
            <option value="recovered">Recovered</option>
          </select>
        </div>
      </div>
      <div className={styles.options}>
        <div>
          <input
            type="checkbox"
            checked={isLogScale}
            onChange={e => setLogScale(e.target.checked)}
          />{" "}
          Log scale
        </div>
        <div>
          Align curve with threshold:{" "}
          <select
            onChange={e => {
              setAlign(e.target.value)
            }}
            value={align}
          >
            <option value="none">None</option>
            <option value="10">10</option>
            <option value="100">100</option>
            <option value="1000">1000</option>
            <option value="10000">10000</option>
            <option value="100000">100000</option>
            <option value="1000000">1000000</option>
          </select>
        </div>
      </div>
      <div className={styles.table}>
        <table>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Country-State</th>
              <th className={styles.center}>Confirmed</th>
              <th className={styles.center}>Deaths</th>
              <th className={styles.center}>New deaths</th>
              <th className={styles.center}>Recovered</th>
            </tr>
          </thead>
          <tbody>
            {values.map(({ node }) => (
              <tr
                key={`${node.country}-${node.state}`}
                className={styles.line}
                onClick={() => {
                  const key = `${node.country}-${node.state}`
                  const value = !selected[key]
                  if (value) {
                    downloadIfNeeded(key)
                  }
                  setSelected({
                    ...selected,
                    [key]: value,
                  })
                }}
              >
                <td>
                  <input
                    type="checkbox"
                    checked={selected[`${node.country}-${node.state}`] === true}
                    onChange={e => {
                      const key = `${node.country}-${node.state}`
                      if (e.target.checked) {
                        downloadIfNeeded(key)
                      }
                      setSelected({
                        ...selected,
                        [key]: e.target.checked,
                      })
                    }}
                  />
                </td>
                <td>
                  <Link to={`/${node.slug}`}>
                    {node.country} {node.state ? ` - ${node.state}` : ""}
                  </Link>
                </td>
                <td className={`${styles.confirmed} ${styles.center}`}>
                  {node.confirmed}{" "}
                </td>
                <td className={`${styles.deaths} ${styles.center}`}>
                  {node.deaths}
                </td>
                <td className={`${styles.bgDeaths}  ${styles.center}`}>
                  +{node.deathsIncrease}
                </td>
                <td className={`${styles.recovered} ${styles.center}`}>
                  {node.recovered}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    countries: allCountryDataTimeseries(
      sort: { fields: confirmed, order: DESC }
    ) {
      edges {
        node {
          id
          country
          state
          confirmed
          confirmedIncrease
          deaths
          deathsIncrease
          recovered
          recoveredIncrease
          slug
        }
      }
    }
  }
`

export default IndexPage
