import React from "react"

import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts"

const htmlColors = [
  "red",
  "blue",
  "green",
  "cyan",
  "orange",
  "purple",
  "pink",
  "black",
  "brown",
]

const colorForIndex = index => {
  return htmlColors[index % htmlColors.length]
}

const ComparisonGraph = ({ data, selected, currentMode, isLogScale }) => {
  const availableKeys = Object.keys(data).filter(key => {
    return selected[key] && typeof data[key] == "object" && data[key] !== null
  })

  let maxDomain = 0

  const dataLines = availableKeys
    .map(key => {
      return data[key].map(x => {
        maxDomain = Math.max(maxDomain, x[currentMode])
        return {
          date: x.date,
          [`${key}-confirmed`]: x.confirmed,
          [`${key}-deaths`]: x.deaths,
          [`${key}-recovered`]: x.recovered,
        }
      })
    })
    .filter(x => x)

  const mergedData = dataLines.reduce((soFar, value) => {
    let newResult = []
    for (let i = 0; i < value.length; i++) {
      newResult[i] = { ...soFar[i], ...value[i] }
    }
    return newResult
  }, [])

  return (
    <ResponsiveContainer minWidth={300} minHeight={400}>
      <LineChart
        data={mergedData}
        margin={{ top: 0, right: 0, bottom: 0, left: 50 }}
      >
        {availableKeys.map((key, index) => (
          <Line
            type="monotone"
            dataKey={`${key}-${currentMode}`}
            name={key}
            stroke={colorForIndex(index)}
            dot={false}
            strokeWidth={1}
            key={key}
          />
        ))}

        <CartesianGrid stroke="#ccc" />
        <XAxis dataKey="date" tickCount={5} />
        {isLogScale === true && (
          <YAxis
            scale="log"
            type="number"
            domain={[1, Math.ceil((maxDomain / 100) * 2) * 100]}
          />
        )}
        {isLogScale === false && (
          <YAxis
            type="number"
            domain={[0, Math.ceil((maxDomain / 100) * 1.1) * 100]}
            allowDecimals={false}
            tickCount={6}
          />
        )}

        <Tooltip />
        <Legend />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default ComparisonGraph
